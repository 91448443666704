// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import loadable from '@loadable/component';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
  COLUMNS,
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  PERMISSIONS,
  ROUTES,
  THEMES,
  TITLES,
  TYPES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcSortBy, AcFormatDate, AcFormatInternalURI } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-configuration-detail-overview-tab',
};

const AcConfigurationDetailOverviewTab = ({
  data,
  store: { configurations, equipment, ui, companies },
}) => {
  const { can } = usePermissions();

  const handleRemoveEquipment = (object) => {
    const { object_no: name, id } = object;

    ui.confirm({
      instance: configurations,
      title: TITLES.REMOVE_EQUIPMENT,
      content: `<p>You are about to remove equipment <strong>${name}</strong> from this configuration.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, remove',
        callback: () => configurations.remove_equipment(data.id, object),
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, company, created_at, updated_at, mutator } = data;

    const items = [
      [
        {
          label: 'Name',
          value: name || '-',
        },
      ],
      [
        {
          label: 'Created date',
          value:
            AcFormatDate(created_at, null, DATETIME_FORMATS.FULL_DATE) || '-',
        },
        {
          label: 'Last modified date',
          value:
            AcFormatDate(updated_at, null, DATETIME_FORMATS.FULL_DATE) || '-',
        },
        {
          label: 'Last modified by',
          value: mutator?.name || '-',
        },
      ],
    ];

    if (can(PERMISSIONS.CONFIGURATION.READ_ALL)) {
      items[0].push({
        label: 'Assigned to company',
        value: company?.name || '-',
        type: TYPES.LINK,
        to:
          company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          ),
      });
    }

    return <AcDetailsCard title={TITLES.DETAILS} items={items} />;
  }, [data]);

  const renderEquipmentTable = useMemo(() => {
    if (configurations.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    if (!AcIsSet(data.equipment) || data.equipment.length === 0)
      return (
        <AcEmptyBlock
          message={'No equipment assigned to this configuration yet.'}
        />
      );

    const { equipment, is_updatable } = data;

    let rows = equipment.map((item) => ({
      ...item,
      'type.series': item?.type?.series,
      'type.group': item?.type?.group,
      'type.type': item?.type?.type,
    }));

    rows = AcSortBy({
      collection: rows,
      field: 'type.group',
      direction: 'asc',
    });

    const options = {
      columns: COLUMNS.EQUIPMENT,
      rows,
      pagination: null,
      withIcon: true,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, configurations.is_loading]);

  const renderProjectsTable = useMemo(() => {
    if (configurations.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    if (!AcIsSet(data.projects) || data.projects.length === 0)
      return (
        <AcEmptyBlock
          message={'No projects found this configuration appears in.'}
        />
      );

    const { projects } = data;

    const options = {
      columns: COLUMNS.CONFIGURATION_PROJECTS,
      rows: projects,
      pagination: null,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, configurations.is_loading]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn>
            <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
              Configuration details
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12} sm={6}>
            {renderDetails}
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              Equipment
            </AcHeading>
            <AcRichContent
              content={'<p>The equipment assigned to this configuration.</p>'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderEquipmentTable}
            </AcCard>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcHeading tag={'h2'} rank={5}>
              Projects
            </AcHeading>
            <AcRichContent
              content={'<p>Appears in the following projects.</p>'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcCard dense flat>
              {renderProjectsTable}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcConfigurationDetailOverviewTab));
