// Imports => React
import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import loadable from '@loadable/component';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Data
import { COUNTRIES_LIST } from '@data/countries.data';

// Imports => Constants
import {
  ICONS,
  KEYS,
  ROUTES,
  PERMISSIONS,
  THEMES,
  TITLES,
  TYPES,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcSortBy,
  AcFormatDate,
  AcFormatRequestParameters,
  AcFormatInternalURI,
  AcHasErrors,
  AcFormatRawDataAsList,
} from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcAddUserModal from '@molecules/ac-add-user-modal/ac-add-user-modal.web';
import AcAddProjectModal from '@molecules/ac-add-project-modal/ac-add-project-modal.web';
import AcSelectContract from '@molecules/ac-import-company/components/ac-select-contract';
import AcSelectEquipment from '@molecules/ac-import-company/components/ac-select-equipment';
import AcEditUserCredentialsModal from '@molecules/ac-edit-user-credentials-modal/ac-edit-user-credentials-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
const AcWizardModal = loadable(() =>
  import('@components/ac-wizard-modal/ac-wizard-modal')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import {
  getUsersTableOptions,
  getContractsTableOptions,
  getProjectsTableOptions,
} from './helpers';

const _CLASSES = {
  MAIN: 'ac-company-detail-overview-tab',
};

const hasProtocolPrefix = (url) => url.match(/^(http|https)?:\/\//);

const AcCompanyDetailOverviewTab = ({
  data, // => current_company, prop passed by @views/ac-company-detail.
  store: { auth, companies, contracts, projects, erp, profile, ui, users },
}) => {
  const { can, cannot } = usePermissions();
  const navigate = useNavigate();
  const { is_busy: companies_is_busy } = companies;

  const submitSelectedContracts = async () => {
    await erp.store();
    if (navigate) navigate(ROUTES.COMPANY_DETAIL.path.replace(':id', data.id));
  };

  const steps = useMemo(() => {
    const { current_company } = companies;
    return [
      {
        title: 'Select contracts from ' + current_company?.name,
        children: <AcSelectContract />,
        submitButton: { disabled: !current_company?.name },
      },
      {
        title: 'Select equipment from ' + current_company?.name,
        children: <AcSelectEquipment />,
      },
    ];
  }, [erp.currentSelectedContracts, companies.current_company?.name]);

  const AcWizardModalBody = useMemo(() => {
    return (
      <AcWizardModal
        onSubmit={submitSelectedContracts}
        onCancel={erp.resetAll}
        steps={steps}
        progress
      />
    );
  }, [steps]);

  const displayAddContractModal = (erpId, companyName) => async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      body: AcWizardModalBody,
      progress: true,
      closeable: false,
      visible: true,
      actions: [],
      forceRerender: true,
      width: 600,
    });
    await erp.set(KEYS.COMPANY, {
      erp_id: erpId,
      name: companyName,
    });
  };

  const displayAddProjectModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.NEW_PROJECT,
      body: (
        <AcAddProjectModal currentCompany={data.id} submit={projects.store} />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayAddUserModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.NEW_USER,
      progress: {
        total_steps: 2,
        current_step: 0,
      },
      body: (
        <AcAddUserModal
          currentCompany={data.id}
          withCompany={false}
          multistep={{
            roles: true,
            companies: false,
          }}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleDeleteContract = (companyId) => (object) => {
    const { order_number: name, id } = object;
    ui.confirm({
      instance: contracts,
      title: TITLES.DELETE_CONTRACT,
      content: `<p>You are about to permanently delete contract <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new Promise((resolve, reject) => {
            contracts
              .delete({ id, name })
              .then(async (resolve) => {
                await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
                await companies.get_by_id(companyId);
                resolve();
              })
              .catch((error) => {
                const errors = AcHasErrors(error);
                if (!AcHasErrors(error)) return;

                ui.display_linked_to({
                  errors,
                  title: TITLES.FAILED_TO_DELETE_CONTRACT,
                });

                reject(error);
              });
          });
        },
      },
    });
  };

  const handleDeleteProject = (companyId) => (object) => {
    const { name, id } = object;
    ui.confirm({
      instance: projects,
      title: TITLES.DELETE_CONTRACT,
      content: `<p>You are about to permanently delete project <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new Promise((resolve, reject) => {
            projects
              .delete({ id, name })
              .then(async (resolve) => {
                await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
                await companies.get_by_id(companyId);
                resolve();
              })
              .catch((error) => {
                const errors = AcHasErrors(error);
                if (!AcHasErrors(error)) return;

                ui.display_linked_to({
                  errors,
                  title: TITLES.FAILED_TO_DELETE_PROJECT,
                });

                reject(error);
              });
          });
        },
      },
    });
  };

  const handleDeleteUser = (obj) => {
    const list = new AcFormatRawDataAsList(
      obj,
      { ID: 'id', NAME: 'name' },
      null,
      true
    );
    const { id, name } = list;

    ui.confirm({
      instance: users,
      title: TITLES.DELETE_USER,
      content: `<p>You are about to permanently delete user <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new Promise((resolve, reject) => {
            users
              .delete({ id, name })
              .then(async () => {
                await companies.get_by_id(data.id);
                resolve();
              })
              .catch((error) => {
                const errors = AcHasErrors(error);
                if (!AcHasErrors(error)) return;

                ui.display_linked_to({
                  errors,
                  title: TITLES.FAILED_TO_DELETE_USER,
                });

                reject(error);
              });
          });
        },
      },
    });
  };

  const handleImpersonate = async (obj) => {
    const list = new AcFormatRawDataAsList(
      obj,
      { ID: 'id', NAME: 'name' },
      null,
      true
    );
    const { id, name } = list;
    await auth.impersonate({ id, name });
    await profile.who_am_i().then(() => {
      if (navigate) navigate('/', { replace: true });
    });
  };

  const handleViewUserDetails = async (obj) => {
    const list = new AcFormatRawDataAsList(
      obj,
      { ID: 'id', NAME: 'name' },
      null,
      true
    );
    const { id } = list;
    const route = AcFormatInternalURI(
      { id, entity: KEYS.USER },
      'View user details'
    );

    if (navigate && route) navigate(route);
  };

  const displayEditOperatorCredentialsModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_DEFAULT_OPERATOR_CREDENTIALS,
      body: (
        <AcEditUserCredentialsModal
          data={{
            ...data,
            jean_lutz_username: data?.basic_operator_username,
            basic_password: data?.basic_operator_password,
          }}
          submit={(data, fields) => {
            const payload = {
              name: data.name,
              basic_operator_username: fields.username,
              basic_operator_password: fields.password,
            };
            return companies.update(data.id, payload);
          }}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { id, name, country_code, website } = data;

    const country = AcIsSet(country_code) && COUNTRIES_LIST[country_code];

    const url = hasProtocolPrefix(website) ? website : `https://${website}`;
    const weburl =
      (website &&
        `<a href="${url}" target="_blank"><span>${website}</span><i class="ac-icon ac-icon--open-in-new" /></a>`) ||
      '-';

    const items = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Country',
        value: country || '-',
      },
      {
        label: 'Website',
        value: weburl,
      },
    ];

    return <AcDetailsCard title={TITLES.DETAILS} items={items} />;
  }, [data]);

  const renderOperatorDetails = useMemo(() => {
    if (!data) return null;

    const { basic_operator_password, basic_operator_username } = data;

    const items = [
      {
        label: 'Username',
        value: basic_operator_username || '-',
      },
      {
        label: 'Password',
        value: basic_operator_password || '-',
      },
    ];

    const edit =
      can(PERMISSIONS.BASIC_PASSWORD.UPDATE) &&
      displayEditOperatorCredentialsModal;

    return (
      <AcDetailsCard title={TITLES.BASIC_OPERATOR} items={items} edit={edit} />
    );
  }, [data]);

  const renderContractsTable = useMemo(() => {
    if (companies.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    let { options, list } = getContractsTableOptions(data);
    if (!list || !list.length)
      return <AcEmptyBlock message={'No results found to display.'} />;

    const actions = can(PERMISSIONS.CONTRACT.MANAGE) && [
      [
        {
          label: 'Delete',
          field: KEYS.OBJECT,
          icon: ICONS.TRASH_CAN_OUTLINE,
          type: TYPES.DELETE,
          callback: handleDeleteContract(data.id),
        },
      ],
    ];
    options = {
      ...options,
      actions,
    };
    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, companies.is_loading]);

  const renderProjectsTable = useMemo(() => {
    if (companies.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    let { options, list } = getProjectsTableOptions(data);
    if (!list || !list.length)
      return <AcEmptyBlock message={'No results found to display.'} />;

    const actions = can(PERMISSIONS.CONTRACT.MANAGE) && [
      [
        {
          label: 'Delete',
          field: KEYS.OBJECT,
          icon: ICONS.TRASH_CAN_OUTLINE,
          type: TYPES.DELETE,
          callback: handleDeleteProject(data.id),
        },
      ],
    ];
    options = {
      ...options,
      actions,
    };
    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, companies.is_loading]);

  const renderUsersTable = useMemo(() => {
    if (companies.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    let { options, list } = getUsersTableOptions(data);
    if (!list || list.length === 0)
      return <AcEmptyBlock message={'No results found to display.'} />;
    options = {
      ...options,
      actions: [
        [
          {
            label: 'View user details',
            icon: ICONS.ACCOUNT_OUTLINE,
            type: TYPES.LINK,
            disabled: cannot(PERMISSIONS.USER.READ),
            callback: handleViewUserDetails,
          },
        ],
        [
          {
            label: 'Impersonate',
            icon: ICONS.NINJA,
            callback: handleImpersonate,
            disabled: cannot(PERMISSIONS.USER.IMPERSONATE),
          },
        ],
        [
          {
            label: 'Delete',
            icon: ICONS.TRASH_CAN_OUTLINE,
            type: TYPES.DELETE,
            disabled: cannot(PERMISSIONS.USER.MANAGE),
            callback: handleDeleteUser,
          },
        ],
      ],
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, companies.is_loading]);

  const getAddContractButtonOptions = useMemo(() => {
    const { erp_id = '', name = '' } = data || {};
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Import Contract',
      disabled: cannot(PERMISSIONS.CONTRACT.MANAGE),
      callback: displayAddContractModal(erp_id, name),
    };
  }, []);

  const getAddProjectButtonOptions = useMemo(() => {
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Add project',
      disabled: cannot(PERMISSIONS.PROJECT.CREATE),
      callback: displayAddProjectModal,
    };
  }, []);

  const getAddUserButtonOptions = useMemo(() => {
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Add User',
      disabled: cannot(PERMISSIONS.USER.MANAGE),
      callback: displayAddUserModal,
    };
  }, []);

  const getSyncButtonOptions = useMemo(() => {
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Synchronize',
      loading: companies.is_busy,
      disabled: cannot(PERMISSIONS.COMPANY.IMPORT),
      callback: async (event) => {
        if (event?.persist) event?.persist();
        if (event?.preventDefault) event?.preventDefault();
        await companies.sync(data.id).then(() => {
          window.location.reload(true);
        });
      },
    };
  }, [companies_is_busy]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={3}>
            <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
              Company details
            </AcHeading>
          </AcColumn>

          <AcColumn xs={12} sm={3} className={'h-text--align-right'}>
            <AcButton {...getSyncButtonOptions}>
              <AcIcon icon={ICONS.SYNC} />
              <span>Start synchronize</span>
            </AcButton>
          </AcColumn>

          <AcCheckPermissions allowed={PERMISSIONS.BASIC_PASSWORD.READ}>
            <AcColumn xs={12} sm={6}>
              <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                Default Operator details
              </AcHeading>
            </AcColumn>
          </AcCheckPermissions>
        </AcRow>

        <AcRow>
          <AcColumn xs={12} sm={6}>
            {renderDetails}
          </AcColumn>

          <AcCheckPermissions allowed={PERMISSIONS.BASIC_PASSWORD.READ}>
            <AcColumn xs={12} sm={6}>
              {renderOperatorDetails}
            </AcColumn>
          </AcCheckPermissions>
        </AcRow>

        {/* CONTRACTS */}
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              {TITLES.CONTRACTS}
            </AcHeading>
            <AcRichContent
              content={'<p>The contracts that are linked to this company.</p>'}
            />
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-text--align-right'}>
            <AcButton {...getAddContractButtonOptions}>
              <AcIcon icon={ICONS.PLUS} />
              <span>Import contract</span>
            </AcButton>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderContractsTable}
            </AcCard>
          </AcColumn>
        </AcRow>

        {/* PROJECTS */}
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              {TITLES.PROJECTS}
            </AcHeading>
            <AcRichContent
              content={'<p>The projects that are linked to this company.</p>'}
            />
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-text--align-right'}>
            <AcButton {...getAddProjectButtonOptions}>
              <AcIcon icon={ICONS.PLUS} />
              <span>Add project</span>
            </AcButton>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderProjectsTable}
            </AcCard>
          </AcColumn>
        </AcRow>

        {/* USERS */}
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              {TITLES.USERS}
            </AcHeading>
            <AcRichContent
              content={'<p>The users that are linked to this company.</p>'}
            />
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-text--align-right'}>
            <AcButton {...getAddUserButtonOptions}>
              <AcIcon icon={ICONS.PLUS} />
              <span>Add user</span>
            </AcButton>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderUsersTable}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcCompanyDetailOverviewTab));
